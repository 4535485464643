import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LoginRequest, LoginResponse, PasswordResetRequest, PasswordResetResponse } from 'src/app/auth/model/login';
import { RootState } from 'src/app/store/reducers';
import { ClientUserAction } from 'src/app/store/reducers/client-user.reducer';
import { AppConfigService } from 'src/app/app-config.service';

@Injectable()
export class AuthService {
  private AUTH_URL = AppConfigService.appConfig.api.authUrl;
  private APPL_URL = AppConfigService.appConfig.api.applUrl;
  private BASE_URL = AppConfigService.appConfig.api.baseUrl;

  constructor(private store: Store<RootState>, private http: HttpClient, private router: Router, @Inject(DOCUMENT) private document: Document) { }

  public set(response: LoginResponse) {
    localStorage.clear();
    localStorage.setItem('env', `${response.env}`);
    localStorage.setItem('client', `${response.client}`);
    localStorage.setItem('username', `${response.username}`);
    localStorage.setItem('token', `${response.token}`);
    const expirationDate = new Date(response.expiration);
    localStorage.setItem('expiration', response.expiration.toString());
    localStorage.setItem('expirationDate', `${expirationDate}`);

    localStorage.setItem('role', `${response.role}`);
    localStorage.setItem('impersonate', `${response.impersonate}`);
    localStorage.setItem('schema', `${response.schema}`);
    localStorage.setItem('version', `${response.version}`);

    if (response.ouser != null) {
      localStorage.setItem('ouser', `${response.ouser}`);
    } else {
      localStorage.removeItem('ouser');
    }
    if (response.otoken != null) {
      localStorage.setItem('otoken', `${response.otoken}`);
    } else {
      localStorage.removeItem('otoken');
    }
  }

  public getUsername(): string { return localStorage.getItem('username'); }
  public getToken(): string { return localStorage.getItem('token'); }
  public getOuser(): string { return localStorage.getItem('ouser'); }
  public getOtoken(): string { return localStorage.getItem('otoken'); }
  public getRole(): string { return localStorage.getItem('role'); }
  public getVersion(): string { return localStorage.getItem('version'); }
  // public getError(): string { return localStorage.getItem('error'); }

  public getSchema(): string { return localStorage.getItem('schema'); }
  public getFullVersion(): string { let version: string = this.getVersion(); return version; }
  public isImpersonate(): boolean { return localStorage.getItem('impersonate') == 'true'; }
  public isImpersonating(): boolean { return localStorage.getItem('otoken') != null; }
  // public setError(error: string) { localStorage.setItem("error", `${error}`); }

  public clearToken(): void { localStorage.clear(); }

  public login(credentials: LoginRequest, altPassword: string): Observable<LoginResponse> {
    const body: LoginRequest = { ...credentials, altPassword: altPassword };
    return this.http.post<LoginResponse>(`${this.AUTH_URL}/auth/login`, body);
  }
  // check client/email and code resend
  public sendCode(client: string, username: string): Observable<PasswordResetResponse> {
    const body: PasswordResetRequest = { client: client, username: username };
    return this.http.post<PasswordResetResponse>(`${this.AUTH_URL}/auth/sendcode`, body);
  }
  public validateCode(client: string, username: string, code: string): Observable<PasswordResetResponse> {
    const body: PasswordResetRequest = { client: client, username: username, code: code };
    return this.http.post<PasswordResetResponse>(`${this.AUTH_URL}/auth/validatecode`, body);
  }
  public resetPassword(client: string, username: string, code: string, newPassword: string): Observable<PasswordResetResponse> {
    const body: PasswordResetRequest = { client: client, username: username, code: code, newPassword: newPassword };
    return this.http.post<PasswordResetResponse>(`${this.AUTH_URL}/auth/resetpassword`, body);
  }
  public setPassword(oldPassword: string, newPassword: string): Observable<PasswordResetResponse> {
    const body: PasswordResetRequest = { oldPassword: oldPassword, newPassword: newPassword };
    return this.http.post<PasswordResetResponse>(`${this.BASE_URL}/common/setpassword`, body);
  }

  public impersonate(payeeId: number): Observable<LoginResponse> {
    return this.http.get<LoginResponse>(`${this.BASE_URL}/manager/system/impersonate/${payeeId}`);
  }
  public umimpersonate(): Observable<LoginResponse> {
    return this.http.get<LoginResponse>(`${this.BASE_URL}/agent/system/unimpersonate`);
  }
  public logOut(errorMessage: string): void {
    this.store.dispatch(ClientUserAction.ClearClientUser());
    this.clearToken();
    // if (errorMessage != null) this.setError(errorMessage);
    // this.document.location.href = this.APPL_URL;
    // AppActions.setSelectedTabs([]);
    // this.document.location.href = AppConfigService.appConfig.api.applUrl;
    if (errorMessage != null) {
      this.router.navigate(['/auth/login', { message: errorMessage }], { replaceUrl: true });
    }
  }

  public isExpired(): boolean {
    const expiration = localStorage.getItem('expiration');
    // console.log("::isExpired: expiration=", +expiration);
    // const res = new Date() > new Date(+expiration);
    const expired = new Date() > new Date(+expiration);
    // console.log("::isExpired: now=", new Date());
    // console.log("::isExpired: expiration2=", new Date(+expiration));
    // console.log("::isExpired: expired=", expired);
    return expired;
  }
}
