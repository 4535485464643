import { Action, createReducer, on } from '@ngrx/store';
import { ClientUser } from '../model/client-user';
import * as ClientUserAction from '../actions/client-user.actions';

export { ClientUserAction }
export const clientUserFeatureKey = 'clientUser';

export interface State {
  clientUser: ClientUser;
}

export const initialState: State = {
  clientUser: null
};


export const reducer = createReducer(
  initialState,
  on(ClientUserAction.LoadClientUser, (state: State, clientUser: ClientUser) => ({ ...state, clientUser: clientUser})),
  on(ClientUserAction.ClearClientUser, (state: State) => ({ ...initialState}))

);

export const getClientUser = (state: State) => state.clientUser;